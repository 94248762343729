<template>
  <div class="layout_common rent_withdrawal">
    <div class="main_box">
      <div class="main_box_con">
        <searchToChargeback
          compType="rent-withdrawal"
          @search="search"
          @reset="search"
          @saleArea="saleArea"
        />
        <div class="echart" v-loading="loading">
          <barEchart
            id1="rent_box"
            id2="rent_echart"
            :propData="echartData"
            :barColor="['#5470c6', '#fac858']"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import searchToChargeback from '@/views/data-center/components/searchToChargeback'
import barEchart from '../components/agingBar'
import { monthRentWithdrawal } from '@/api'
import { formatDate, diffDate } from '@/utils/common'
import { statModelList } from '@/utils/constData'
export default {
  components: {
    searchToChargeback,
    barEchart
  },
  data () {
    return {
      loading: false,
      type: 1,
      startMonth: '',
      endMonth: '',
      level: '',
      cityId: '',
      salesArea: [],
      reason: '',
      storeId: '',
      storeName: '',
      echartData: []
    }
  },
  computed: {
    statModelList () {
      return statModelList
    }
  },
  methods: {
    getList () {
      this.loading = true
      const params = {
        type: this.type,
        startMonth: this.startMonth,
        endMonth: this.endMonth,
        level: this.level,
        cityId: this.cityId,
        reason: this.reason,
        storeId: this.storeId
      }
      monthRentWithdrawal(params).then(res => {
        this.loading = false
        this.handleEchartData(res.data)
      })
    },
    // 根据后台返回的数据进行处理成图表的格式的数据
    handleEchartData (data) {
      if (data.length === 0) {
        this.echartData = []
        return
      }
      const dataX = data.map(item => item.month)
      // 在租设备隐藏 暂时不需要
      // const rentInNum = data.map(item => item.rentInNum)
      // const rentInPrice = data.map(item => item.rentInPrice)
      const rentNewNum = data.map(item => item.rentNewNum)
      const rentNewPrice = data.map(item => item.rentNewPrice)
      const rentWithdrawalNum = data.map(item => item.rentWithdrawalNum)
      const rentWithdrawalPrice = data.map(item => item.rentWithdrawalPrice)
      const arr = [
        // { name: '在租设备', value: rentInNum, price: rentInPrice, dataX },
        { name: '新增设备', value: rentNewNum, price: rentNewPrice, dataX },
        {
          name: '退租设备',
          value: rentWithdrawalNum,
          price: rentWithdrawalPrice,
          dataX
        }
      ]
      this.echartData = arr
    },
    // 子组件传过来战区信息
    saleArea (salesArea, level, cityId) {
      this.level = level
      this.cityId = cityId
      // 缺省默认6个月
      this.endMonth = formatDate(new Date()).slice(0, 7)
      this.startMonth = diffDate(-5, 'months', true).slice(0, 7)
      this.getList()
    },
    search (val) {
      const {
        level,
        cityId,
        type,
        timeType,
        storeId,
        storeName,
        startTime,
        endTime,
        reason
      } = val
      this.level = level
      this.cityId = cityId
      this.type = type
      this.timeType = timeType
      this.storeId = storeId
      this.storeName = storeName
      this.startMonth = startTime
      this.endMonth = endTime
      this.reason = reason
      this.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.rent_withdrawal {
  margin-bottom: 20px;
  .echart {
    height: calc(100% - 120px);
  }
  .month_box {
    display: flex;
    align-items: center;
  }
}
</style>
